import { Icon } from '@iconify-icon/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon } from 'lucide-react'
import ShimmerButton from 'components/magicui/shimmer-button'
import Link from 'next/link'
import { Box } from 'components/primitives'
import Image from 'next/image'
import { useMarketplaceChain } from 'hooks'
import GlobalSearch from './GlobalSearch'

interface NavItem {
  name: string
  description: string
  href: string
  icon: string
}

interface NavbarData {
  [key: string]: NavItem[]
}

interface NavSectionProps {
  title: string
  items: NavItem[] | undefined
}

const formatTitle = (key: string): string => {
  return key
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const NavSection: React.FC<NavSectionProps> = ({ title, items }) => {
  const handleItemClick = (href: string) => {
    if (title === 'Market Data' || href === '/portfolio') {
      const baseUrl = 'https://app.sharpe.ai/'
      window.open(`${baseUrl}${href}`, '_self')
    } else if (title === 'Community') {
      window.open(href, '_blank'); 
    } else {
      window.open(href, '_self')
    }
  }

  if (!items || items.length === 0) {
    return null
  }

  const regularItems = title === 'Trading' ? items.slice(0, -1) : items
  const stakeItem = title === 'Trading' ? items[items.length - 1] : null

  return (
    <Popover className="relative">
      <PopoverButton className="inline-flex items-center gap-x-1 text-sm leading-6 text-neutral-300 hover:text-white focus:outline-none focus:ring-0 focus:border-none active:ring-0 active:border-none">
        <span>{title}</span>
        <ChevronDownIcon aria-hidden="true" className="h-4 w-4" />
      </PopoverButton>
      <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-b-3xl rounded-t-3xl bg-[#0E0E10] text-sm leading-6 shadow-lg ring-1 ring-[#27272A] lg:max-w-3xl">
          <div className="grid grid-cols-1 max-h-[600px] overflow-y-auto gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
            {regularItems.map((item) => (
              <div
                key={item.name}
                className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-[#111113]"
              >
                <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-[#0e0d0d]">
                  <Icon
                    icon={item.icon}
                    className="text-[20px] text-neutral-500 group-hover:text-[#fff]"
                  />
                </div>
                <div>
                  <a
                    onClick={() => handleItemClick(item.href)}
                    className="font-semibold text-white group-hover:text-white cursor-pointer"
                  >
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-neutral-500">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          {stakeItem && (
            <div
              className="bg-[#111113] px-8 py-6 cursor-pointer"
              onClick={() => handleItemClick(stakeItem.href)}
            >
              <div className="flex items-center gap-x-3">
                <h3 className="text-sm font-semibold leading-6 text-neutral-300">
                  {stakeItem.name}
                </h3>
                <p className="rounded-full bg-neutral-500/10 px-2.5 py-1.5 text-xs font-semibold text-[#fff]">
                  New
                </p>
              </div>
              <p className="mt-2 text-sm leading-6 text-neutral-500">
                {stakeItem.description}
              </p>
            </div>
          )}
        </div>
      </PopoverPanel>
    </Popover>
  )
}

const CACHE_KEY = 'navbarData'

export default function SharpeNavbar() {
  const [openSearch, setOpenSearch] = useState(false)
  const [navbarData, setNavbarData] = useState<NavbarData | null>(() => {
    if (typeof window !== 'undefined') {
      const cachedData = localStorage.getItem(CACHE_KEY)
      return cachedData ? JSON.parse(cachedData) : null
    }
    return null
  })
  console.log('navbarData', navbarData)
  const { routePrefix } = useMarketplaceChain()
  const mainWebsiteApiUrl =
    process.env.NODE_ENV === 'production'
      ? process.env.NEXT_PUBLIC_API_URL
      : process.env.NEXT_PUBLIC_LOCAL_API_URL

  useEffect(() => {
    const fetchNavbarData = async () => {
      try {
        const response = await fetch(`${mainWebsiteApiUrl}/navbar`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const newData = await response.json()
        setNavbarData(newData)

        if (typeof window !== 'undefined') {
          localStorage.setItem(CACHE_KEY, JSON.stringify(newData))
        }
      } catch (error) {
        console.error('Error fetching navbar data:', error)
      }
    }

    fetchNavbarData()
  }, [mainWebsiteApiUrl])

  const handleSearchInputClick = () => {
    setOpenSearch(true)
  }

  const handleCloseSearch = () => {
    setOpenSearch(false)
  }

  const handleKeydown = useCallback(
    (event: any) => {
      if (
        !openSearch &&
        event.which === 191 &&
        (event.ctrlKey || event.metaKey)
      ) {
        setOpenSearch(true)
      }
    },
    [openSearch]
  )

  const handleKeyUp = useCallback(
    (event: any) => {
      // ** ESC key to close searchbox
      if (openSearch && event.keyCode === 27) {
        setOpenSearch(false)
      }
    },
    [openSearch]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyUp, handleKeydown])

  return (
    <div className="w-full flex items-center justify-between px-3">
      <a
        style={{ textDecoration: 'none', color: 'inherit' }}
        href="https://sharpe.ai/"
      >
        <Image
          src={'/sharpe-new-logo.svg'}
          alt=""
          width={110}
          height={60}
        />
      </a>
      <div className="max-w-md ml-2" onClick={handleSearchInputClick}>
        <div className="relative flex items-center cursor-pointer border border-[#343338] px-2 py-0 w-full h-10 rounded-lg focus-within:shadow-lg bg-transparent overflow-hidden">
          <div className="flex items-center">
            <div className="grid place-items-center h-full w-12 text-neutral-300">
              <Icon
                icon="fluent:search-16-regular"
                width="22"
                height="22"
                style={{ color: '#fff', cursor: 'pointer' }}
              />
            </div>
            <span className="peer h-full w-full outline-none bg-transparent text-sm text-neutral-400 pr-2 pl-1">
              Search
            </span>
            <p className="text-sm flex items-center gap-1 text-neutral-500">
              Press{' '}
              <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded  bg-[#27272A] px-1.5 font-mono text-[10px] font-medium text-neutral-300 opacity-100">
                <span className="text-md">⌘</span>/
              </kbd>
            </p>
          </div>
        </div>
      </div>

      <div className="flex-1 hidden lg:flex mr-44 justify-center items-center lg:gap-x-2 xl:gap-x-4 2xl:gap-x-6">
        {navbarData &&
          Object.entries(navbarData).map(([key, items]) => (
            <NavSection key={key} title={formatTitle(key)} items={items} />
          ))}
      </div>
      <div className="z-10 flex items-center justify-center">
        <ShimmerButton
          className="shadow-2xl"
          onClick={() => window.open('https://stake.sharpe.ai/', '_self')}
        >
          <span className="whitespace-pre-wrap text-center py-0 text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-[14px]">
            Earn $SAI
          </span>
        </ShimmerButton>
      </div>
      <GlobalSearch openSearch={openSearch} handleClose={handleCloseSearch} />
    </div>
  )
}
